<template lang="">
  <div>
    <b-row class="match-height">
      <b-col cols="12" xl="12" lg="12" md="12">
        <InquiryActions :inquiry-entry="inquiryById" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col cols="12" xl="12" lg="12" md="12">
        <InquiryInfoCards :inquiry-entry="inquiryById" />
        <!-- <InquiryList :inquiry-entry="inquiryById" /> -->
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col cols="12" xl="12" lg="12" md="12">
        <InquiryList :inquiry-entry="inquiryById" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col cols="12" xl="12" lg="12" md="12">
        <InquiryOffers :inquiry-entry="inquiryById" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import InquiryActions from './InquiriesViewActions.vue'
import InquiryInfoCards from './InquiriesViewInfoCards.vue'
import InquiryList from './InquiriesViewInquiries.vue'
import InquiryOffers from './InquiriesOffersTableView.vue'

export default {
  components: {
    BRow,
    BCol,
    InquiryActions,
    InquiryInfoCards,
    InquiryList,
    InquiryOffers,
    BCard,
  },
  props: ['id'],
  computed: {
    inquiryById() {
      return this.$store.getters.inquiryById(Number(this.id))
    },
  },
}
</script>
<style lang=""></style>
