<template>
  <div>
    <!-- User Info: Left col -->
    <b-card no-body>
      <b-table
        ref="refListTable"
        striped
        hover
        :current-page="currentPage"
        :per-page="perPage"
        :items="inquiryEntry.storageJSON.storage"
        :fields="fields"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        responsive
        show-empty
        empty-text="Keine Einträge gefunden"
      />
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Zeige {{ dataMeta.from }} bis {{ dataMeta.to }} von
              {{ dataMeta.of }} Einträge</span
            >
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="getTotalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BTable,
  BPagination,
} from 'bootstrap-vue'
import helper from '@/helper/helper'
import tableHelper from '../../Table+Helper'

export default {
  setup() {
    const { getFormattedDate } = helper()
    const {
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    } = tableHelper()

    return {
      getFormattedDate,
      perPageOptions,
      filter,
      perPage,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BTable,
    BPagination,
  },
  props: {
    inquiryEntry: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      sortBy: 'id',
      sortDesc: true,
      fields: [
        {
          key: 'type',
          label: 'Typ',
          sortable: true,
        },
        {
          key: 'priceStorage',
          label: 'Preis',
          sortable: true,
        },
        {
          key: 'numberStorage',
          label: 'Anzahl',
          sortable: true,
        },
        {
          key: 'estimatedStartRent',
          label: 'Mietbegin ca.',
          sortable: true,
        },
        {
          key: 'estimatedEndRent',
          label: 'Mietdauer ca.',
          sortable: true,
        },
      ],
    }
  },

  computed: {
    getTotalRows() {
      return this.inquiryEntry.storageJSON.storage.length
    },
  },

  updated() {
    // Set the initial number of items
    this.totalRows = this.getTotalRows
  },
}
</script>
<style lang="scss">
hr.style1 {
  border-top: 1px solid #8c8b8b;
}
hr.style5 {
  background-color: #fff;
  border-top: 2px dashed #8c8b8b;
}
.per-page-selector {
  width: 90px;
}
</style>
