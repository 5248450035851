<template>
  <div>
    <!-- {{ inquiryEntry }} -->
    <b-card>
      <b-row>
        <b-col>
          <b-button-toolbar justify>
            <!--group 1 -->
            <b-button-group>
              <b-button
                :disabled="inquiryEntry.offers.length > 0"
                :variant="getButtonVariant(inquiryEntry)"
                @click="showQualificationModal()"
              >
                <feather-icon icon="AlignJustifyIcon" class="mr-50" />
                <span class="align-middle">{{
                  inquiryEntry.qualification
                    ? inquiryEntry.qualification
                    : 'Qualifizieren'
                }}</span>
              </b-button>
            </b-button-group>
            <b-button-group>
              <b-button
                :disabled="!isOfferable(inquiryEntry)"
                :variant="isOfferable(inquiryEntry) ? 'primary' : 'secondary'"
                block
                @click="acceptInquiry"
                ><feather-icon icon="CheckSquareIcon" class="mr-50" />
                <span class="align-middle"
                  >Angebot für {{ inquiryEntry.firstName }}
                  {{ inquiryEntry.lastName }} erstellen</span
                >
              </b-button>
            </b-button-group>

            <!-- group 2 -->
            <b-button-group>
              <b-button
                variant="danger"
                :disabled="isOfferable(inquiryEntry)"
                @click="deleteWarning"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </b-col>
      </b-row>
    </b-card>
    <InquiriesQualificationModal
      ref="modal-qualifaction"
      :selected-option="selectedOption"
      :qualification-options="qualificationOptions"
      :selected-inquiry="inquiryEntry"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BFormCheckbox,
  BButtonToolbar,
  BButtonGroup,
} from 'bootstrap-vue'
import helper from '@/helper/helper'
import inquiriesHelper from '@/helper/inquiriesHelper'
import InquiriesQualificationModal from '../modal/InquiriesQualificationModal.vue'

export default {
  setup() {
    const { getFormattedDate } = helper()
    // eslint-disable-next-line operator-linebreak
    const { getButtonVariant, getStatusVariant, isOfferable } =
      inquiriesHelper()

    return { getFormattedDate, getButtonVariant, getStatusVariant, isOfferable }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BFormCheckbox,
    BButtonToolbar,
    BButtonGroup,
    InquiriesQualificationModal,
  },
  props: {
    inquiryEntry: {
      type: Object,
      required: true,
    },
  },
  methods: {
    showQualificationModal() {
      this.selectedOption = this.inquiryEntry.qualification
      this.$refs['modal-qualifaction'].show()
    },

    acceptInquiry() {
      console.log(
        `acceptInquiry() - inquiryEntry.id: ${this.inquiryEntry.id}`,
        this.inquiryEntry
      )
      this.$router.push({
        name: 'Direktangebot',
        params: { booking: this.inquiryEntry },
      })
    },
    deleteWarning() {
      this.$swal({
        title: 'Bist du sicher?',
        // text: 'Das Lager wird unwiederruflich gelöscht.',
        text: 'Die Anfrage wirklich löschen?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, löschen!',
        cancelButtonText: 'Abbrechen',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteInquiry()
        }
      })
    },
    deleteInquiry() {
      console.log(
        `deleteInquiry() - inquiryEntry.id: ${this.inquiryEntry.id}`,
        this.inquiryEntry
      )
      this.$store.dispatch('deleteInquiry', this.inquiryEntry.id)
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
hr.style1 {
  border-top: 1px solid #8c8b8b;
}
hr.style5 {
  background-color: #fff;
  border-top: 2px dashed #8c8b8b;
}
</style>
