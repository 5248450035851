// create init vue template vue
<template lang="">
  <div>
    <b-card no-body>
      <div class="m-2">
        <h4>Versendete Angebote</h4>
      </div>

      <b-table
        ref="refListTable"
        striped
        hover
        :current-page="currentPage"
        :per-page="perPage"
        :items="inquiryEntry.offers"
        :fields="fields"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        responsive
        show-empty
        empty-text="Keine Einträge gefunden"
        @row-clicked="onRowClicked"
      >
        <template #cell(offerDate)="data">
          {{ getFormattedDate(data.item.offerDate) }}
        </template>
        <template #cell(isActive)="data">
          <b-badge v-if="data.item.isActive" variant="success"> Ja </b-badge>
          <b-badge v-else variant="danger"> Nein </b-badge>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Zeige {{ dataMeta.from }} bis {{ dataMeta.to }} von
              {{ dataMeta.of }} Einträge</span
            >
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="getTotalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import { BRow, BCol, BCard, BTable, BBadge, BPagination } from 'bootstrap-vue'
import helper from '@/helper/helper'
import tableHelper from '../../Table+Helper'

export default {
  setup() {
    const { getFormattedDate } = helper()
    const {
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    } = tableHelper()

    return {
      getFormattedDate,
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BBadge,
    BPagination,
  },
  props: ['inquiryEntry'],

  data() {
    return {
      sortBy: 'id',
      sortDesc: true,
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'offerDate',
          label: 'Datum',
          sortable: true,
        },
        {
          key: 'isActive',
          label: 'AKtiv',
          sortable: true,
        },
      ],
    }
  },

  computed: {
    getTotalRows() {
      return this.inquiryEntry.offers.length
    },
  },
  updated() {
    // Set the initial number of items
    this.totalRows = this.getTotalRows
  },

  methods: {
    onRowClicked(row) {
      this.$router.push({
        name: 'offers-view',
        params: {
          id: row.id,
        },
      })
    },
  },
}
</script>
<style lang=""></style>
