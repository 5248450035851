<template>
  <div>
    <b-row class="match-height">
      <!-- User Info: Left col -->
      <b-col cols="12" xl="8" lg="7" md="12">
        <!-- Name & Email -->
        <b-card>
          <span>{{ inquiryEntry.salutation }}</span>
          <h3>{{ inquiryEntry.firstName }} {{ inquiryEntry.lastName }}</h3>
          <span class="card-text">{{ inquiryEntry.email }}</span>

          <hr class="style1" />

          <!-- Right Col: Table -->

          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="UsersIcon" class="mr-75" />
                <span class="font-weight-bold">Unternehmen</span>
              </th>
              <td class="pb-50">
                {{ inquiryEntry.organizationName }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">
                <feather-icon icon="MapIcon" class="mr-75" />
                <span class="font-weight-bold">Strasse</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ inquiryEntry.street }} {{ inquiryEntry.streetNumber }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">
                <feather-icon icon="MapPinIcon" class="mr-75" />
                <span class="font-weight-bold">PLZ | Stadt</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ inquiryEntry.zipCode }} {{ inquiryEntry.city }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">Telefon</span>
              </th>
              <td>
                {{ inquiryEntry.phoneNumber }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="SmartphoneIcon" class="mr-75" />
                <span class="font-weight-bold">Mobile</span>
              </th>
              <td>
                {{ inquiryEntry.mobileNumber }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="PrinterIcon" class="mr-75" />
                <span class="font-weight-bold">Fax</span>
              </th>
              <td>
                {{ inquiryEntry.faxNumber }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="FlagIcon" class="mr-75" />
                <span class="font-weight-bold">Land</span>
              </th>
              <td>
                {{ inquiryEntry.country }}
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <b-col cols="12" xl="4" lg="5" md="12">
        <b-card title="">
          <h5>Bewertung</h5>
          <b-card-text>
            <span class="align-middle">{{ inquiryEntry.qualification }}</span>
          </b-card-text>
          <h5>Status</h5>

          <b-card-text>
            <span class="align-middle">{{
              inquiry.getStatus(inquiryEntry)
            }}</span>
          </b-card-text>

          <!-- <b-button
            :disabled="!inquiryEntry.offer"
            class="mb-1"
            variant="primary"
            @click="$router.push('/offers-view/' + inquiryEntry.offer.id)"
          >
            Angebot öffnen
          </b-button> -->

          <!-- <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span> -->
          <!-- <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span> -->

          <h5>Erstellt</h5>
          <b-card-text>
            {{ getFormattedDate(inquiryEntry.created_at) }}
          </b-card-text>

          <h5>Leadquelle</h5>
          <b-card-text>
            {{ inquiryEntry.leadSource }}
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="12" xl="12" lg="12" md="12">
        <b-card title="Beschreibung">
          <b-card-text>
            {{ inquiryEntry.message }}
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import helper from '@/helper/helper'
import Inquiry from '@/store/inquiry/Inquiry'
import inquiriesHelper from '@/helper/inquiriesHelper'

export default {
  setup() {
    const { getFormattedDate } = helper()
    const { isOfferable } = inquiriesHelper()

    return { getFormattedDate, isOfferable }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BFormCheckbox,
    Inquiry,
  },
  props: {
    inquiryEntry: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      inquiry: Inquiry,
    }
  },
}
</script>
<style lang="scss">
hr.style1 {
  border-top: 1px solid #8c8b8b;
}
hr.style5 {
  background-color: #fff;
  border-top: 2px dashed #8c8b8b;
}
</style>
